import React, { useState } from "react";
import SEO from "../components/global/Seo";
import Layout from "../components/layout/Layout";
import Lightbox from "react-image-lightbox";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Masonry from "react-masonry-css";

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const query = useStaticQuery(graphql`
    query {
      bannerImg2: file(relativePath: { eq: "szkolenia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gallery: allFile(
        filter: { relativeDirectory: { eq: "galeria_szkolenia" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 600, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      klienci: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "klienci" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout pageTitle="Szkolenia">
      <SEO title="Szkolenia" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div className="text-box-padding-xs-right">
            <h2>Uczymy robić wyjątkową kawę</h2>
            <strong>
              Chcesz rozpocząć swoją przygodę z kawą, udoskonalić już posiadane
              umiejętności lub wyszkolić kompleksowo obsługę lokalu? Jesteś w
              dobrym miejscu!
            </strong>
            <p>
              Bazując na zdobytym do tej pory doświadczeniu oraz wielu
              przebytych szkoleniach gwarantujemy kompleksowe kursy z zakresu
              przygotowania kawy, obsługi ekspresów jak i sztuki LatteArt.
            </p>
            <p>
              Oferta szkoleń jest każdorazowo dostosowywana indywidualne. Napisz
              lub zadzwoń do nas!
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <Masonry
            breakpointCols={2}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {query.gallery.edges.map((edge, i) => {
              return (
                <div
                  role="layout"
                  key={i}
                  className="gallery-item"
                  onClick={() => {
                    setIsOpen("gallery");
                    setPhotoIndex(i);
                  }}
                >
                  <Img
                    fluid={edge.node.childImageSharp.fluid}
                    alt="Szkolenia galeria"
                  />
                </div>
              );
            })}
          </Masonry>
          {!!isOpen && (
            <Lightbox
              mainSrc={
                query.gallery.edges[photoIndex].node.childImageSharp.fluid.src
              }
              nextSrc={
                query.gallery.edges[
                  (photoIndex + 1) % query.gallery.edges.length
                ].node.childImageSharp.fluid.src
              }
              prevSrc={
                query.gallery.edges[
                  (photoIndex + query.gallery.edges.length - 1) %
                    query.gallery.edges.length
                ].node.childImageSharp.fluid.src
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + query.gallery.edges.length - 1) %
                    query.gallery.edges.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % query.gallery.edges.length)
              }
            />
          )}
        </div>
      </div>
      <div className="row align-items-center justify-content-end section-padding-xs image-text-box paragraph-content">
        <div className="box-image">
          <Img fluid={query.bannerImg2.childImageSharp.fluid} alt="Banner" />
        </div>
        <div className="col-md-8 col-lg-6">
          <div className="box-text">
            <h3>Rekomendują nas między innymi</h3>

            <Masonry
              breakpointCols={4}
              className="masonry-grid mt-4"
              columnClassName="masonry-grid_column"
            >
              {query.klienci.edges.map((edge, i) => {
                return (
                  <div key={i}>
                    <Img
                      key={i}
                      fluid={edge.node.childImageSharp.fluid}
                      alt="Klient logo"
                    />
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
    </Layout>
  );
};
